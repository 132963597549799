import Vue from 'vue'
import Router from 'vue-router'

// Page content
import Detail from '@/components/Detail'
import Home from '@/components/Home'
import Popular from '@/components/Popular'
import Category from '@/components/Category'
import Tag from '@/components/Tag'
import Site from '@/components/Site'

// Fallback page
import PageNotFound from '@/components/PageNotFound'

Vue.use(Router)
function cekIsSite(to, from, next)
{
    if (to.name === 'Category' && to.params.category !== undefined) {
        const publisher = to.params.category;
        const listPublisher = ['kapanlagi', 'liputan6', 'merdeka', 'dream', 'bolacom', 'fimela', 'otosia', 'trstdly'];
        if (listPublisher.includes(publisher)) {
            next({ name: 'Site', params: { site: to.params.category }});
        }
        else{
            next();
        }
    }
    else{
        next();
    }
}
export default new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'Home',
        components: {default: Home,enamplus: Category}
    },
    {
        path: '/popular',
        name: 'Popular',
        component: Popular
    },
    {
        path: '/read/:id/:slug',
        name: 'Detail Article',
        component: Detail
    },
    {
        path: '/tag/:idtag/:tag',
        name: 'Tag',
        component: Tag
    },
    {
        path: '/:category',
        name: 'Category',
        beforeEnter : cekIsSite,
        component: Category
    },
    {
        path: '/:site',
        name: 'Site',
        component: Site
    },
    {
        path: '**',
        name: 'PageNotFound',
        component: PageNotFound
    }]
})