import axios from 'axios'
import config from '@/config/config'

export default {
    get_article(token, page, domain, callback)
    {
        let api = config.get('api');
        if(domain === 'enamplusliputan6'|| domain.includes('enamplus'))
        {
            return axios.get(api.url + 'api/article/?filter[publisher_id]=2,6&filter[type_id]=3&lip6id=1&apps=0&page='+page+'&is_silkroad=1',{
                headers: {
                    'Authorization': 'Bearer '+token
                }
            }).then(function(rs){ callback(rs) }).catch(function(e){ console.log(["error",e]) });
        }
        else
        {
            let id_publisher = 2;
            // if(domain === 'todaykapanlagi') {
            //     id_publisher = 3;
            // } else if(domain === 'todaymerdeka') {
            //     id_publisher = 4;
            // }else if(domain === 'todayotosia') {
            //     id_publisher = 13;
            // }
            if(domain.includes('trend')||domain.includes('today')) {
                if(domain.includes('kapanlagi')) {
                    id_publisher = 3;
                } else if(domain.includes('merdeka')) {
                    id_publisher = 4;
                }else if(domain.includes('otosia')) {
                    id_publisher = 13;
                }else if(domain.includes('fimela')) {
                    id_publisher = 8;
                }
            }
            return axios.get(api.url + 'api/article/?filter[publisher_id]='+id_publisher+'&lip6id=1&filter[type_id]=1&limit=20&apps=0&page='+page,{
                headers: {
                    'Authorization': 'Bearer '+token
                }
            }).then(function(rs){ callback(rs) }).catch(function(e){ console.log(["error",e]) });
        }
        
    },
    get_popular_article(token, page, domain, callback)
    {
        let api = config.get('api');
        let id_publisher = 2;
        // if(domain === 'todaykapanlagi') {
        //     id_publisher = 3;
        // } else if(domain === 'todaymerdeka') {
        //     id_publisher = 4;
        // }else if(domain === 'todayotosia') {
        //     id_publisher = 13;
        // }
        if(domain.includes('trend')||domain.includes('today')) {
            if(domain.includes('kapanlagi')) {
                id_publisher = 3;
            } else if(domain.includes('merdeka')) {
                id_publisher = 4;
            } else if(domain.includes('otosia')) {
                id_publisher = 13;
            } else if(domain.includes('fimela')){
                id_publisher = 8;
            }
        }
        return axios.get(api.url + 'api/headline/?filter[type_id]=1&filter[publisher_id]='+id_publisher+'&lip6id=1&apps=0&page='+page,{
            headers: {
                'Authorization': 'Bearer '+token
            }
        }).then(function(rs){ callback(rs) }).catch(function(e){ console.log(["error",e]) });
    },
    get_tag_article(id_tag, token, page, domain, callback)
    {
        let api = config.get('api');
        let id_publisher = '2,6'; //liputan6.com & bola.com & kapanlagi.com
        let id_type = 3;
        // if(domain === 'todaykapanlagi') {
        //     id_publisher = 3;
        //     id_type = 1;
        // } else if(domain === 'todaymerdeka') {
        //     id_publisher = 4;
        //     id_type = 1;
        // }
        if(domain.includes('trend')||domain.includes('today')) {
            if(domain.includes('kapanlagi')) {
                id_publisher = 3;
                id_type = 1;
            } else if(domain.includes('merdeka')) {
                id_publisher = 4;
                id_type = 1;
            } else if(domain.includes('fimela')){
                id_publisher = 8;
                id_type = 1;
            }
        }
        let url = api.url + 'api/article/tag/'+id_tag+'?filter[publisher_id]='+id_publisher+'&lip6id=1&filter[type_id]='+id_type+'&limit=20&apps=0&page='+page+'&is_silkroad=1';
        return axios.get(url,{
            headers: {
                'Authorization': 'Bearer '+token
            }
        }).then(function(rs){callback(rs) }).catch(function(e){ console.log(["error",e]) });
    },
    get_category_article(category, token, page, domain, callback)
    {
        let api = config.get('api');
        let id_publisher = '2,6'; //liputan6.com & bola.com & kapanlagi.com
        let id_type = 3;

        // if(domain === 'todaykapanlagi') {
        //     id_publisher = 3;
        //     id_type = 1;
        // } else if(domain === 'todaymerdeka') {
        //     id_publisher = 4;
        //     id_type = 1;
        // }
        if(domain.includes('trend')||domain.includes('today')) {
            if(domain.includes('kapanlagi')) {
                id_publisher = 3;
                id_type = 1;
            } else if(domain.includes('merdeka')) {
                id_publisher = 4;
                id_type = 1;
            } else if(domain.includes('fimela')){
                id_publisher = 8;
                id_type = 1;
            }
        }
        if(category === 'video') {
            category = "";
        }
        let url = api.url + 'api/article?filter[publisher_id]='+id_publisher+'&lip6id=1&filter[type_id]='+id_type+'&limit=20&apps=0&real_category='+category+'&page='+page+'&is_silkroad=1';
        return axios.get(url,{
            headers: {
                'Authorization': 'Bearer '+token
            }
        }).then(function(rs){callback(rs) }).catch(function(e){ console.log(["error",e]) });
    },
    get_publisher_article(publisher, token, page, callback)
    {
        let api = config.get('api');
        let domains = [];
        domains['kapanlagi'] = 3;
        domains['liputan6'] = 2;
        domains['merdeka'] = 4;
        domains['dream'] = 11;
        domains['bolacom'] = 6;
        //domains['bolanet'] = 7;
        domains['fimela'] = 8;
        domains['otosia'] = 13;
        domains['trstdly'] = 28;
        let id_publisher = domains[publisher];
        let id_type = 3;
        let url = api.url + 'api/article?filter[publisher_id]='+id_publisher+'&lip6id=1&filter[type_id]='+id_type+'&limit=20&apps=0&page='+page;

        //trstdly
        if (id_publisher = 28){
            url += '&publish_start=2024-01-01'
        }

        return axios.get(url,{
            headers: {
                'Authorization': 'Bearer '+token
            }
        }).then(function(rs){callback(rs) }).catch(function(e){ console.log(["error",e]) });
    },
    get_detail_article(token, id, callback)
    {
        let api = config.get('api');
        return axios.get(api.url + 'api/liputan6id/article/'+id,{
            headers: {
                'Authorization': 'Bearer '+token
            }
        }).then(function(rs){ callback(rs) }).catch(function(e){ console.log(["error",e]) });
    },
    get_token(param, callback)
    {
        let api = config.get('api');
        var client_id = api.client_id;
        var client_secret = api.client_secret;

        if (param.login == 0) {
            client_id = api.before_login.client_id;
            client_secret = api.before_login.client_secret;
        }

        return axios.post(api.url + 'oauth/token', {
            grant_type: api.grant_type,
            client_id: client_id,
            client_secret: client_secret,
        }).then(function(rs){ callback(rs, param) }).catch(function(e){ console.log(["error",e]) });;
    },
    status_login(callback, callback_error)
    {
        var token1 = config.get('csrf_token').substr(0, 10);
        var token2 = config.get('csrf_token').substr(10);

        var date = ("0" + new Date().getDate()).slice(-2);
        var month = ("0" + (new Date().getMonth() + 1)).slice(-2);
        var year = new Date().getFullYear().toString().substr(2);
        
        let url = config.get('base_url');
        return axios.get(url + '/login/status/',{
            headers: {
                'Authorization': 'Bearer '+ token1 + month + date + year + token2,
            }
        }).then(function(rs){ callback(rs) }).catch(function(e){ console.log(["error",e]); callback_error(); });
    },
    get_video_vertical_article(category, token, page, domain, callback)
    {
        let api = config.get('api');
        let id_publisher = '2,8,11'; //2=liputan6, 8=fimela, 11=dream
        let id_type = 3;

        // if(domain === 'todaykapanlagi') {
        //     id_publisher = 3;
        // } else if(domain === 'todaymerdeka') {
        //     id_publisher = 4;
        // }
        if(domain.includes('trend')||domain.includes('today')) {
            if(domain.includes('kapanlagi')) {
                id_publisher = 3;
            } else if(domain.includes('merdeka')) {
                id_publisher = 4;
            }
        }
        if(category === 'video') {
            category = "";
        }
        let today = new Date();
        let id_tag_vertical = 414962;
        let publish_end=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        today.setMonth(today.getMonth() - 1);
        let publish_start=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        // let url = api.url + 'api/article/tag/'+id_tag_vertical+'?filter[publisher_id]='+id_publisher+'&lip6id=1&filter[type_id]='+id_type+'&limit=5&apps=0&video_ratio=vertical&publish_start='+publish_start+'&publish_end='+publish_end;
        let url = api.url + 'api/article?filter[publisher_id]='+id_publisher+'&lip6id=1&filter[type_id]='+id_type+'&limit=5&apps=0&video_ratio=vertical&publish_start='+publish_start+'&publish_end='+publish_end;
        return axios.get(url,{
            headers: {
                'Authorization': 'Bearer '+token
            }
        }).then(function(rs){callback(rs) }).catch(function(e){ console.log(["error",e]) });
    },
    get_tags(token, callback){
        let api = config.get('api');
        let id_publisher = 2;
        let id_type = 3;
        let url = api.url + 'api/tags?filter[publisher_id]='+id_publisher;
        return axios.get(url,{
            headers: {
                'Authorization': 'Bearer '+token
            }
        }).then(function(rs){callback(rs) }).catch(function(e){ console.log(["error",e]) });
    }
}