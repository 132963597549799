<template>
    <div v-if="loadingRouteData">
        <loading></loading>
    </div>
    <div class="infscroll" v-else-if='!loadingRouteData'>
        <ul class="list-article list-unstyled">
            <li v-for="(article, index) in articles" v-bind:key="index">
                <div class="item">
                    <figure class="image">
                        <a :class="['default-'+subDomain, domain.includes('enamplus')?'default-enam-plus':'',domain.includes('otosia')?'default-otosia':'']" @click="link_detail(article.title,article.id,article.uniqueId,article.source,article.is_silkroad)">
                            <img :src="get_image(article.photos)" :alt="article.title" @error="img_on_error">
                        </a>
                    </figure>
                    <div class="description">
                        <a @click="link_detail(article.title,article.id,article.uniqueId,article.source,article.is_silkroad)"><h6 v-html="article.title"></h6></a>
                        <span class="time">{{ article.published_at | formatPublishedAt }}</span>
                    </div>
                </div>
            </li>
        </ul>
        <div class="infscroll-loader" v-if="process_load_api"></div>
    </div>
</template>

<script>
import Api from '@/services/Api'
import config from '@/config/config'
import { global_function } from '@/global_function.js'

export default {
    mixins: [global_function],
    data () {
        return {
            articles_default: [],
            articles_all: [],
            articles: [],
            articles_show: 0,
            limit: 6,
            token: null,
            current_page: 1,
            loadingRouteData: this.$loadingRouteData,
            process_load_api: false,
            subDomain: 'liputan6id',
            params: {}
        }
    },
    created: function() {
        this.loadingRouteData = true;
        setTimeout(() => {
            //window.scrollTo({top: 0,behavior: 'smooth'});
            window.scrollTo(0,0);
        }, 100);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () 
    {
        window.removeEventListener('scroll', this.handleScroll);
    },
    updated () 
    {
        elementResize();
    },
    mounted () {
        var self = this;
        var reupdate = false;
        var last_update = localStorage.getItem(config.get(`stored.${this.$store.state.auth.login}.last_update`));
        var base_url = config.get("base_url");
        var domain = "liputan6id";
        if ( base_url.split('.').length>1) {
            this.subDomain=base_url.split('.')[1];
        }
        if(base_url.indexOf('today.') > 0) {
            const array = base_url.split('.');
            domain = "today"+array[1];
        }
        if(base_url.indexOf('enamplus.') > 0) {
            const array = base_url.split('.');
            domain = "enamplus"+array[1];
        }
        if(base_url.indexOf('trend.') > 0) {
            const array = base_url.split('.');
            domain = "trend"+array[1];
        }
        self.domain = domain;
        
        if(last_update != null)
        {
            last_update = new Date(last_update);
            let now = new Date();
            let diff =(now.getTime() - last_update.getTime()) / 1000;
            diff /= 60;
            diff = Math.abs(Math.round(diff));

            if(diff >= 10)
            {
                self.current_page = 1;
                reupdate = true;
                self.setCookie(config.get(`session.homepage_current_page`), 1, 0.1);
            }
        }else{
            reupdate = true;
            self.current_page = 1;
            self.setCookie(config.get(`session.homepage_current_page`), 1, 0.1);
        }

        // get token
        self.token = localStorage.getItem(config.get(`stored.${this.$store.state.auth.login}.token`));

        if(!reupdate && self.token != null && self.token != undefined) //get articles from cache
        {
            let articles_store = localStorage.getItem(config.get(`stored.${this.$store.state.auth.login}.list_articles`));
            self.articles_all = JSON.parse(articles_store);

            let articles_slice = self.articles_all.slice(self.articles_show, self.limit);
            articles_slice.forEach(function(article){
                self.articles.push(article);
                self.articles_show++;
            });

            let photo = self.get_image(self.articles_all[0].photos);
            
            if (domain.includes('trend')) {
                self.update_meta({
                    title: document.head.querySelector('meta[name=title]').content,
                    description: document.head.querySelector('meta[name=description]').content,
                    image: photo,
                    url: config.get('base_url')
                });
            } else {
                self.update_meta({
                    image: photo,
                    url: config.get('base_url')
                });
            }
            
            self.loadingRouteData = false;
            self.handleScroll();
        }
        else //get articles from api
        {
            if(self.token != null && self.token != undefined) // if token is not null
            {
                Api.get_article(self.token,self.current_page, self.domain, function(rs){ // get articles 20 first from api 
                    self.current_page++;
                    self.articles_all = rs.data.data;
                    let articles_slice = self.articles_all.slice(self.articles_show, self.limit);
                    articles_slice.forEach(function(article){
                        self.articles.push(article);
                        self.articles_show++;
                    });

                    let photo = self.get_image(self.articles_all[0].photos);
                    if (domain.includes('trend')) {
                        self.update_meta({
                            title: document.head.querySelector('meta[name=title]').content,
                            description: document.head.querySelector('meta[name=description]').content,
                            image: photo,
                            url: config.get('base_url')
                        });
                    } else {
                        self.update_meta({
                            image: photo,
                            url: config.get('base_url')
                        });
                    }
                    
                    self.loadingRouteData = false;

                    //update cache
                    localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.last_update`), new Date().toString() );
                    localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_articles`), JSON.stringify(self.articles_all) );

                    self.setCookie(config.get(`session.homepage_current_page`), rs.data.meta.pagination.current_page, 0.1);
                    self.handleScroll();
                });
            }
            else // if token is null
            {
                Api.get_token(this.$store.state.auth, function(rs, rs_param){ // get token from api
                    localStorage.setItem(config.get(`stored.${rs_param.login}.token`), rs.data.access_token);
                    self.token = rs.data.access_token;
                    Api.get_article(self.token,self.current_page, self.domain, function(rs){ // get articles from api
                        self.current_page++;
                        self.articles_all = rs.data.data;
                        let articles_slice = self.articles_all.slice(self.articles_show, self.limit);
                        articles_slice.forEach(function(article){
                            self.articles.push(article);
                            self.articles_show++;
                        });
                        
                        let photo = self.get_image(self.articles_all[0].photos);

                        if (domain.includes('trend')) {
                            self.update_meta({
                                title: document.head.querySelector('meta[name=title]').content,
                                description: document.head.querySelector('meta[name=description]').content,
                                image: photo,
                                url: config.get('base_url')
                            });
                        } else {
                            self.update_meta({
                                image: photo,
                                url: config.get('base_url')
                            });
                        }
                        
                        self.loadingRouteData = false;

                        //update cache
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.last_update`), new Date().toString() );
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_articles`), JSON.stringify(self.articles_all) );

                        self.setCookie(config.get(`session.homepage_current_page`), rs.data.meta.pagination.current_page, 0.1);
                        self.handleScroll();
                    });
                });
            }
        }
    },
    methods: {
        link_detail: function(article_title, article_id, article_uid, source, silkroad)
        {
            var slug = article_title.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
            var articleId = silkroad ? 'v'+article_id : article_id;
            var articleUid = silkroad ? 'v'+article_uid : article_uid;
            if(this.domain == 'liputan6id')
            {
                return this.$router.push({path:'/read/'+articleId+'/'+slug, params:{ id: article_id, slug:slug }});
            }
            else if(this.domain.includes('today') || this.domain.includes('trend'))
            {
                if(this.domain.includes('kapanlagi') || this.domain.includes('merdeka'))
                {
                    return window.location.href= config.get('base_url')+"/"+ articleUid.replace('liputan6.com-', '');
                    // return window.location.href= config.get('base_url')+"/"+ article_id
                }
                else
                {
                    return window.location.href= config.get('base_url')+"/"+ articleUid.replace('liputan6.com-', '');
                }
            }
            else if(this.domain == 'enamplusliputan6')
            {
                if (source.includes('bola.com')) {
                    source = source.split('.');
                    source[1] = 'liputan6';
                    source = source.join('.');
                }
                
                var path = source.replace(/^[^.]*/, 'https://enamplus');

                if(config.get("environtment") == 'devel')
                {
                    var path = source.replace(/^[^.]*/, 'https://staging-enamplus');
                }
                
                return window.location.href= path;
            }
            else
            {
                return window.location.href= config.get('base_url')+"/"+ articleUid.replace('liputan6.com-', '').replace('silkroad-', '');
            }
            
        },
        load_more: function()
        {
            let self = this;
            let offset = self.limit + self.articles_show;
            if (self.getCookie(config.get(`session.homepage_current_page`)) == null) {
                self.current_page = Math.ceil( self.articles_all.length / 20 ) + 1;
            } else {
                self.current_page = parseInt(self.getCookie(config.get(`session.homepage_current_page`))) + 1;
            }

            if(self.articles_all.length < offset)
            {
                if(self.process_load_api == false)
                {
                    self.process_load_api = true;
                
                    Api.get_article(self.token,self.current_page, self.domain, function(rs){
                        let unique_article = self.unique_article(rs.data.data);

                        self.articles_all = self.articles_all.concat(unique_article);
                        //update cache
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.last_update`), new Date().toString() );
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_articles`), JSON.stringify(self.articles_all) );

                        let articles_slice = self.articles_all.slice(self.articles_show, offset);
                        
                        articles_slice.forEach(function(article){
                            self.articles.push(article);
                            self.articles_show++;
                        });
                        self.current_page++;
                        self.process_load_api = false;

                        self.setCookie(config.get(`session.homepage_current_page`), rs.data.meta.pagination.current_page, 0.1);
                        let page = self.articles_show / 6;

                        self.$gtm.trackEvent({
                            event: 'pageview', // Event type [default = 'interaction'] (Optional)
                            hitType: 'pageview',
                            page: self.$route.path +'?page='+ page,
                            title: document.title,
                            location: config.get('base_url'),
                            noninteraction: false // Optional
                        },{
                            'dimension1':'',
                            'dimension2': 'root',
                            'dimension3':'',
                            'dimension4':''
                        });
                        // ga('send',{
                        //     hitType: 'pageview',
                        //     page: self.$route.path +'?page='+ page,
                        //     title: document.title,
                        //     location: config.get('base_url')
                        // },{
                        //     'dimension1':'',
                        //     'dimension2': 'root',
                        //     'dimension3':'',
                        //     'dimension4':''
                        // });
                    });
                }
            }
            else
            {
                let articles_slice = self.articles_all.slice(self.articles_show, offset);
                articles_slice.forEach(function(article){
                    self.articles.push(article);
                    self.articles_show++;
                });

                let page = self.articles_show / 6;

                self.$gtm.trackEvent({
                    event: 'pageview', // Event type [default = 'interaction'] (Optional)
                    hitType: 'pageview',
                    page: self.$route.path +'?page='+ page,
                    title: document.title,
                    location: config.get('base_url'),
                    noninteraction: false // Optional
                },{
                    'dimension1':'',
                    'dimension2': 'root',
                    'dimension3':'',
                    'dimension4':''
                });
                // ga('send',{
                //     hitType: 'pageview',
                //     page: self.$route.path +'?page='+ page,
                //     title: document.title,
                //     location: config.get('base_url')
                // },{
                //     'dimension1':'',
                //     'dimension2': 'root',
                //     'dimension3':'',
                //     'dimension4':''
                // });
            }
        },
        handleScroll (event) 
        {
            var self = this;
            let el = document.getElementById("footer");
            let bottom = this.ismobile() ? 450 : 550;
            if ( ((window.innerHeight + window.scrollY) >= (el.offsetTop - bottom)) ) 
            {
                this.load_more();
            }
        },
        unique_article: function(list_article)
        {
            let self = this;
            let ids = self.articles_all.map(function (v) { return v.id; });

            let found = list_article.filter(function(v) {
                var exists = ids.indexOf(v.id);
                return exists < 0;
            });
            
            return found;
        }
    }
}
</script>