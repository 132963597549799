<template>
    <div v-if="loadingRouteData">
        <loading></loading>
    </div>

    <div v-else-if='!loadingRouteData'>
    <div class="detail">
        <div class="detail-headline" id="detail-headline">
            <span class="time" style="font-size:12px;">https://lip6.id/{{ article.id }}</span>
            <h2 v-html="article.title"></h2>
            <span class="time">{{ article.published_at | formatDate }}</span>
        </div>
        <div class="detail-img">
            <figure>
                <img :src="get_image(article.photos)" :alt="article.title" @error="img_on_error">
            </figure>
            <p class="detail-img-copyright" v-html="article.lead"></p>
        </div>
        <div class="infinite">
            <div class="infinite-box detail-paragraph" v-html='decodeHtml(article.body)'></div>
            <div v-for="(page, index) in pages" class="infinite-box detail-paragraph" v-bind:key="index" v-show="$store.state.auth.login == 1">
                <h2 v-html="page.page_title"></h2>
                <img :src="page.page_cover" :alt="page.page_title" v-if="page.page_cover != null" style="margin-bottom: 20px;" @error="img_on_error">
                <div v-html='decodeHtml(page.page_body)'></div>
            </div>
        </div>

        <div class="infinite" style="margin-top: 40px;">
            <div class="infinite-box detail-paragraph">Sumber : {{ article.publisher.publisher_name }}</div>
        </div>
        <div class="infscroll-loader" v-if="load_pages"></div>
    </div>
    <div class="box" id="another-article" v-show="$store.state.auth.login == 1">
        <h5 class="box-title">Berita Lainnya</h5>
        <div class="infscroll">
            <ul id="infscroll-1" class="list-article list-unstyled infscroll-box">
                <li v-for="(related, index) in relateds" v-bind:key="index">
                    <div class="item" v-bind:id="'related-article-'+index">
                        <figure class="image">
                            <a @click="link_detail(related.title,related.id, index)">
                                <img :src="get_image(related.photos)" :alt="related.title" @error="img_on_error">
                            </a>
                        </figure>
                        <div class="description">
                            <a @click="link_detail(related.title,related.id, index)"><h6 v-html="related.title"></h6></a>
                            <span class="time">{{ related.published_at | formatDate }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    </div>
</template>

<script>
import Api from '@/services/Api'
import config from '@/config/config'
import { global_function } from '@/global_function.js'

export default {
    mixins: [global_function],
    data () {
        return {
            articles: [],
            param: {},
            article: {
                title: '',
                photos: [{
                    photo_url: ''
                }],
                'published_at': ''
            },
            detail: true,
            title: "Detail Article Karsa",
            pages_all: [],
            pages: [],
            relateds: [],
            loadingRouteData: this.$loadingRouteData,
            load_pages: false,
            slug_before: null
        }
    },
    created: function() {
        this.loadingRouteData = true;
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () 
    {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted () {
        var self = this;
        self.param = this.$route.params;
        let article_id = self.param.id;
        let token = localStorage.getItem(config.get(`stored.${this.$store.state.auth.login}.token`));

        if(token == null || token == undefined){
            Api.get_token(this.$store.state.auth, function(rs, rs_param){ // get token from api
                localStorage.setItem(config.get(`stored.${rs_param.login}.token`), rs.data.access_token);
                token = rs.data.access_token;

                Api.get_detail_article(token, article_id, function(rs){
                    self.article = rs.data.data;
                    self.pages_all = self.article.pages;

                    self.set_meta(); // set meta

                    self.filter_related(self.article.relateds);
                    self.loadingRouteData = false;

                    localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_detail_articles`) + "-" + rs.data.data.id, JSON.stringify(self.article) );
                });
            });
        }
        else if(article_id != null && article_id != undefined)
        {
            let article_stored = localStorage.getItem(config.get(`stored.${this.$store.state.auth.login}.list_detail_articles`) + "-" + article_id );

            if( article_stored != null && article_stored != undefined ){
                self.article = JSON.parse(article_stored);
                
                self.pages_all = self.article.pages;
                self.set_meta(); // set meta

                self.filter_related(self.article.relateds);
                self.loadingRouteData = false;
            }
            else
            {
                Api.get_detail_article(token,article_id, function(rs){
                    self.article = rs.data.data;
                    self.pages_all = self.article.pages;
                    self.set_meta(); // set meta
            
                    self.filter_related(self.article.relateds);
                    self.loadingRouteData = false;

                    localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_detail_articles`) + "-" + rs.data.data.id, JSON.stringify(self.article) );
                });
            }
        }
        else
        {
            //If article id not found
            Api.get_article(token,1, function(rs){
                self.articles = rs.data.data;
                var rs_find = self.find_by_slug(self.articles, self.param.slug);
                if(rs_find !== null){
                    self.article = rs_find;
                    self.pages_all = self.article.pages;
                    self.set_meta(); // set meta

                    self.filter_related(self.article.relateds);
                    self.loadingRouteData = false;

                    localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_detail_articles`) + "-" + rs.data.data.id, JSON.stringify(self.article) );
                }
            });
        }
    },
    methods: {
        link_detail: function(article_title, article_id, index)
        {
            let self = this;
            if((index !== undefined && index !== null) && (self.article !== undefined && self.article !== null))
            {
                let position = index+1;
                let sub_category = self.article.category.category_name;
                sub_category = sub_category !== undefined ? sub_category.toLowerCase() : '';

                let publisher_name = self.article.publisher.publisher_name;
                publisher_name = publisher_name !== undefined ? publisher_name.toLowerCase() : '';

                // self.$gtm.trackEvent({
                //     event: null, // Event type [default = 'interaction'] (Optional)
                //     category: 'berita-lainnya',
                //     action: 'click',
                //     label: 'berita-lainnya::'+position+'::'+sub_category+'::'+publisher_name,
                //     noninteraction: false // Optional
                // });
                // ga('send', 'event', 'berita-lainnya', 'click', 'berita-lainnya::'+position+'::'+sub_category+'::'+publisher_name);
            }

            var slug = article_title.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
            return this.$router.push({path:'/read/'+article_id+'/'+slug, params:{ id: article_id, slug:slug }});
        },
        convert_to_slug: function (text)
        {
            return text.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
        },
        find_by_slug: function(articles,find_slug)
        {
            var rs_find = articles.filter(
                function(article){
                    var slug = article.title.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
                    return slug == find_slug;
                }
            )

            if(rs_find.length >= 1)
            {
                return rs_find[0];
            }

            return null;
        },
        handleScroll (event) 
        {
            var self = this;
            self.load_pages = true;
            let el = document.getElementById("another-article");
            if (el == undefined) {
                el = document.getElementById("unlogin");
            }
            
            if ( el !== undefined && ((window.innerHeight + window.scrollY) >= (el.offsetTop - 250)) && (self.pages.length <= self.pages_all.length) ) {
                let page = self.pages_all[ self.pages.length ];
                if( page !== undefined ){
                    self.pages.push(page);
                }

                self.resize_iframe();
            }

            if(el !== undefined && ((window.innerHeight + window.scrollY) >= (el.offsetTop + 90)) 
                && ((window.innerHeight + window.scrollY) <= (el.offsetTop + 190))
                && self.$route.params.slug !== self.slug_before)
            {
                self.slug_before = self.$route.params.slug;
                
                if(self.article !== undefined && self.article !== null)
                {
                    let sub_category = self.article.category.category_name;
                    sub_category = sub_category !== undefined ? sub_category.toLowerCase() : '';

                    let publisher_name = self.article.publisher.publisher_name;
                    publisher_name = publisher_name !== undefined ? publisher_name.toLowerCase() : '';

                    self.$gtm.trackEvent({
                        event: null, // Event type [default = 'interaction'] (Optional)
                        category: 'berita-lainnya',
                        action: 'impression',
                        label: 'berita-lainnya::'+sub_category+'::'+publisher_name,
                        noninteraction: false // Optional
                    });
                    // ga('send', 'event', 'berita-lainnya', 'impression', 'berita-lainnya::'+sub_category+'::'+publisher_name);
                }
            }

            self.load_pages = false;
        },
        filter_related: function(list_related)
        {
            let self = this;
            let count_related = 0;
            list_related.forEach(function(v, i){

                if(v.source == null || v.source == undefined || count_related >= 3 || v.type.type_id != 1)
                    return;

                //var exists = v.source.search("liputan6.com");
                //if(exists >= 0)
                //{
                    self.relateds.push(v);
                    count_related++;
                //}
            });
        },
        resize_iframe: function()
        {
            let self = this;
            let video_el = document.querySelectorAll(".vidio-embed");
            
            var i;
            for (i = 0; i < video_el.length; i++) {
                if( self.ismobile() )
                {
                    video_el[i].style.height = "200px";
                }
                else
                {
                    video_el[i].style.height = "none";
                }
            }
        },
        set_meta: function()
        {
            let self = this;
            self.update_meta({
                title: self.article.title,
                description: self.article.lead,
                keyword: self.article.title.split(' '),
                url: config.get('base_url')+'read/'+self.param.id+'/'+self.param.slug,
                image: self.get_image(self.article.photos),
                category_name: self.article.category.category_name,
                tag_name: self.article.tags.tag_name,
                published_at: self.article.published_at,
                publisher_name: self.article.publisher.publisher_name
            });
        },
        decodeHtml: function (html) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            let result = txt.value;
            txt.remove();
            
            return result;
        }
    }
}
</script>