<template>
    
    <!-- <div class="infscroll" v-else-if='!loadingRouteData'>
        <ul class="list-article list-unstyled">
            <li v-for="(article, index) in articles" v-bind:key="index">
                <div class="item">
                    <figure class="image">
                        <a :class="domain.includes('enamplus')?'default-enam-plus':''" @click="link_detail(article.title,article.id,article.uniqueId,article.source)">
                            <img :src="get_image(article.photos)" :alt="article.title" @error="img_on_error">
                        </a>
                    </figure>
                    <div class="description">
                        <a @click="link_detail(article.title,article.id,article.uniqueId,article.source)"><h6 v-html="article.title"></h6></a>
                        <span class="time">{{ article.published_at | formatPublishedAt }}</span>
                    </div>
                </div>
            </li>
        </ul>
        <div class="infscroll-loader" v-if="process_load_api"></div>
    </div> -->
    <div>
        <div class="main_body">
            <div :id="domain.includes('enamplus')?'enam':'category'">
                <!-- tab -->
                <div class="section section--tab">
                    <div v-if="($route.name == 'Category')" >
                        <h1>Video {{capitalizeFirstLetter(this.$route.params.category)}}</h1>
                        <p v-if="domain.includes('enamplus')">Tonton video streaming update berita terbaru dan terkini yang sedang viral di kanal {{capitalizeFirstLetter(this.$route.params.category)}} Enamplus Liputan6</p>
                    </div>
                    <div v-if="loadingRouteData">
                        <loading></loading>
                    </div>
                    <div v-else-if="!loadingRouteData" class="tabs" >
                        <!-- <div class="tabs-btn">
                            <div class="tabs-btn-item active" data-tab-target="#terkini">Terkini</div>
                            <div class="tabs-btn-item" data-tab-target="#populer">Terpopuler</div>
                        </div> -->
                       
                        <!-- higlight piala dunia -->
                        <!-- <div class="detail_video_section --index" v-if="articles_video_highlight.length>0 && $route.name=='Home'">
                            <h2 class="detail_video_section_title"> Highlights Piala Dunia 2022</h2>
                            <ul class="detail_video_section_list highlights_list" v-on:scroll="handleScrollHighlights(articles_video_highlight.length)">
                                <li class="detail_video_section_list_item" v-for="(article, index) in articles_video_highlight.slice(0, 5)" v-bind:key="index">
                                    <a @click="link_detail(article.title,article.id,article.uniqueId,article.source,'video-highlights-'+index)">
                                        <img :src="get_image(article.photos)" :alt="article.title" @error="img_on_error" width="190">
                                    </a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- widget gateway pildun
                        <div class="widget widget--pildun" v-if="articles_video_highlight.length>0 && $route.name=='Home'">
                            <h1 class="widget-title"><i class="widget-title-icon"><img class="widget-icon" alt="image" width="14" height="30"></i>PIALA DUNIA QATAR 2022</h1>
                            <div class="widget-ox highlights_list" v-on:scroll="handleScrollHighlights(articles_video_highlight.length)">
                                <div class="widget-ox-item" v-for="(article, index) in articles_video_highlight.slice(0, 7)" v-bind:key="index">
                                    <a @click="link_detail(article.title,article.id,article.uniqueId,article.source,'video-highlights-'+index)" class="widget-ox-item-box">
                                        <div class="widget-ox-item-box-assets">
                                            <img :src="get_image(article.photos)" :alt="article.title" @error="img_on_error" width="190" height="108">
                                            <span class="duration">{{ format_duration(article.videos[0].video_duration) }}</span>
                                        </div>
                                        <div class="widget-ox-item-box-desc">
                                            <p class="widget-ox-item-box-desc-text">{{ article.title }}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <a @click="link_more('/tag/457312/highlights-piala-dunia')" class="widget-linkall">Selengkapnya 
                                <svg class="widget-linkall-icon" width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.209529 0.742334C0.143109 0.808617 0.0904141 0.88735 0.0544604 0.974024C0.0185067 1.0607 0 1.15361 0 1.24745C0 1.34128 0.0185067 1.4342 0.0544604 1.52087C0.0904141 1.60755 0.143109 1.68628 0.209529 1.75256L2.98945 4.53248L0.209529 7.31241C0.0755641 7.44637 0.000303378 7.62807 0.000303378 7.81752C0.000303378 8.00697 0.0755641 8.18867 0.209529 8.32264C0.343493 8.4566 0.525188 8.53186 0.714643 8.53186C0.904098 8.53186 1.08579 8.4566 1.21976 8.32264L4.50837 5.03402C4.57479 4.96773 4.62749 4.889 4.66344 4.80233C4.6994 4.71565 4.7179 4.62274 4.7179 4.5289C4.7179 4.43507 4.6994 4.34215 4.66344 4.25548C4.62749 4.1688 4.57479 4.09007 4.50837 4.02379L1.21976 0.735169C0.947497 0.462908 0.488954 0.462908 0.209529 0.742334Z" fill="#FF4700"/>
                                </svg>
                            </a>
                        </div>
                        -->

                        <div class="tabs-content" style="margin-top: 15px;">
                            <div class="tabs-content-item active" id="terkini" data-tab-content>
                                <ul class="list-article list-none list-unstyled" v-if="articles.length>0">
                                    <li v-for="(article, index) in articles.slice(0, 4)" v-bind:key="index">
                                        <div class="item">
                                            <figure class="image image_article">
                                                <a :class="domain.includes('enamplus')?'default-enam-plus':''" @click="link_detail(article.title,article.id,article.uniqueId,article.source,domain.includes('enamplus')?'video-terkait-'+index:'',article.is_silkroad)">
                                                    <img :src="get_image(article.photos,article.videos)" :alt="article.title" @error="img_on_error" width="345" height="194">
                                                    <!-- <span class="duration">{{ article.videos[0].video_duration }}</span> -->
                                                    <span v-if="article.videos.length>0" class="duration">{{format_duration(article.videos[0].video_duration)}}</span>
                                                </a>
                                            </figure>
                                            <div class="description">
                                                <a @click="link_detail(article.title,article.id,article.uniqueId,article.source,domain.includes('enamplus')?'video-terkait-'+index:'',article.is_silkroad)" class="title">
                                                    <h6 v-html="article.title"></h6>
                                                </a> 
                                                <div class="description-bottom">
                                                    <!-- <router-link :to="{ path: ('/tag/'+article.category.category_id+'/'+article.category.category_name)}" class="tag">{{article.category.category_name}}</router-link> -->
                                                    <span class="tag">{{article.category.category_name}}</span>
                                                    <span class="time">{{ article.published_at | formatPublishedAt }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li v-if="articles_video_vertical.length>0 && $route.name=='Home'">
                                        <div class="detail_video_section --vertical --index">
                                            <h2 class="detail_video_section_title">Spotlights</h2>
                                            <ul class="detail_video_section_list spotlights_list" v-on:scroll="handleScrollSpotlights(articles_video_vertical.length)">
                                                <li class="detail_video_section_list_item" v-for="(article, index) in articles_video_vertical.slice(0, 10)" v-bind:key="index">
                                                    <a @click="link_detail(article.title,article.id,article.uniqueId,article.source,'video-spotlights-'+index,article.is_silkroad)">
                                                        <img :src="get_image(article.photos,article.videos)" :alt="article.title" @error="img_on_error" width="115" height="204">
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li v-for="(article, index) in articles.slice(4, articles.length)" v-bind:key="index">
                                        <div class="item">
                                            <figure class="image image_article">
                                                <a :class="domain.includes('enamplus')?'default-enam-plus':''" @click="link_detail(article.title,article.id,article.uniqueId,article.source,domain.includes('enamplus')?'video-terkait-'+index:'',article.is_silkroad)">
                                                    <img :src="get_image(article.photos,article.videos)" :alt="article.title" @error="img_on_error" width="345" height="194">
                                                    <!-- <span class="duration">{{ article.videos[0].video_duration }}</span> -->
                                                    <span v-if="article.videos.length>0" class="duration">{{format_duration(article.videos[0].video_duration)}}</span>
                                                </a>
                                            </figure>
                                            <div class="description">
                                                <a @click="link_detail(article.title,article.id,article.uniqueId,article.source,domain.includes('enamplus')?'video-terkait-'+index:'',article.is_silkroad)" class="title">
                                                    <h6 v-html="article.title"></h6>
                                                </a> 
                                                <div class="description-bottom">
                                                    <!-- <router-link :to="{ path: ('/tag/'+article.category.category_id+'/'+article.category.category_name)}" class="tag">{{article.category.category_name}}</router-link> -->
                                                    <span class="tag">{{article.category.category_name}}</span>
                                                    <span class="time">{{ article.published_at | formatPublishedAt }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="article-not-found" v-else>
                                    Video tidak ditemukan
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped src="./../css/enamplus.min.css"></style>

<script>
import Api from '@/services/Api'
import config from '@/config/config'
import { global_function } from '@/global_function.js'
// import './../css/enamplus.min.css';

export default {
    mixins: [global_function],
    data () {
        return {
            articles_default: [],
            articles_all: [],
            articles: [],
            articles_video_vertical: [],
            articles_video_highlight:[],
            spotlights_scroll: true,
            highlights_scroll: true,
            articles_show: 0,
            limit: 25,
            token: null,
            current_page: 1,
            loadingRouteData: this.$loadingRouteData,
            process_load_api: false,
            params: {
                enamplus_video_passed:0,
                enamplus_video_spotlight_passed:false,
            },
            stop_load: false,
            domain: "liputan6id"
        }
    },
    created: function() {
        this.loadingRouteData = true;
        setTimeout(() => {
            //window.scrollTo({top: 0,behavior: 'smooth'});
            window.scrollTo(0,0);
        }, 100);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () 
    {
        window.removeEventListener('scroll', this.handleScroll);
    },
    updated () 
    {
        elementResize();
    },
    mounted () {
        var self = this;
        if (this.$route.name=="Home") {
            self.category = "video";
        }else{
            self.category = this.$route.params.category;
        }
        
        var reupdate = false;
        var last_update = localStorage.getItem(config.get(`stored.${this.$store.state.auth.login}.last_update_category`) + "-" + self.category);
        var base_url = config.get("base_url");
        var domain = "liputan6id";
        if(base_url.indexOf('today.') > 0) {
            const array = base_url.split('.');
            domain = "today"+array[1];
        }
        if(base_url.indexOf('trend.') > 0) {
            const array = base_url.split('.');
            domain = "trend"+array[1];
        }
        if(base_url.indexOf('enamplus.') > 0) {
            const array = base_url.split('.');
            domain = "enamplus"+array[1];
        }
        self.domain = domain;
        self.meta_description = config.get("url_description");
        
        if(last_update != null)
        {
            last_update = new Date(last_update);
            let now = new Date();
            let diff =(now.getTime() - last_update.getTime()) / 1000;
            diff /= 60;
            diff = Math.abs(Math.round(diff));

            if(diff >= 10)
            {
                self.current_page = 1;
                reupdate = true;
            }
        }else{
            reupdate = true;
        }

        // get token
        self.token = localStorage.getItem(config.get(`stored.${this.$store.state.auth.login}.token`));

        if(!reupdate && self.token != null && self.token != undefined) //get articles from cache
        {
            if(self.domain === 'enamplusliputan6'|| domain.includes('enamplus')){
                let articles_vertical = localStorage.getItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-video-vertical");
                self.articles_video_vertical = JSON.parse(articles_vertical);

                // higlight piala dunia
                let articles_highlight = localStorage.getItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-video-highlight");
                self.articles_video_highlight = JSON.parse(articles_highlight);
            }

            let articles_store = localStorage.getItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-" + self.category);
            self.articles_all = JSON.parse(articles_store);

            let articles_slice = self.articles_all.slice(self.articles_show, self.limit);
            articles_slice.forEach(function(article){
                self.articles.push(article);
                self.articles_show++;
            });

            let photo = self.get_image(self.articles_all[0].photos);

            // self.update_meta({
            //     image: photo,
            //     url: config.get('base_url')+"-category-"+self.category
            // });
            
            self.loadingRouteData = false;
            self.handleScroll();
        }
        else //get articles from api
        {
            if (self.category == 'bola') {self.category = 'bola,ragam,indonesia';}
            if(self.token != null && self.token != undefined) // if token is not null
            {
                if(self.domain === 'enamplusliputan6'|| domain.includes('enamplus')){
                    // get video vertical
                    Api.get_video_vertical_article(self.category, self.token,self.current_page, self.domain, function(rs){ // get articles 20 first from api 
                        self.current_page++;
                        self.articles_video_vertical = rs.data.data;
                        //update cache
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-video-vertical", JSON.stringify(self.articles_video_vertical) );
                    });
                    // get video highlight piala dunia
                    Api.get_tag_article('457312', self.token,self.current_page, self.domain, function(rs){ // get articles 20 first from api 
                        self.current_page++;
                        self.articles_video_highlight = rs.data.data;
                        //update cache
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-video-highlight", JSON.stringify(self.articles_video_highlight) );
                    });
                }
                Api.get_category_article(self.category, self.token,self.current_page, self.domain, function(rs){ // get articles 20 first from api 
                    self.current_page++;
                    self.articles_all = rs.data.data;
                    let articles_slice = self.articles_all.slice(self.articles_show, self.limit);
                    articles_slice.forEach(function(article){
                        self.articles.push(article);
                        self.articles_show++;
                    });
                    if (rs.data.data.length>0) {
                        let photo = self.get_image(self.articles_all[0].photos);

                        self.update_meta({
                            image: photo,
                            url: config.get('base_url')+"-category-"+self.category
                        });
                        
                        self.loadingRouteData = false;

                        //update cache
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.last_update_category`) + "-" + self.category, new Date().toString() );
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-" + self.category, JSON.stringify(self.articles_all) );

                        self.handleScroll();
                    }else{
                        self.stop_load = true;
                        self.loadingRouteData = false;
                    }

                });
            }
            else // if token is null
            {
                Api.get_token(this.$store.state.auth, function(rs, rs_param){ // get token from api
                    localStorage.setItem(config.get(`stored.${rs_param.login}.token`), rs.data.access_token);
                    self.token = rs.data.access_token;
                    if(self.domain === 'enamplusliputan6'|| domain.includes('enamplus')){
                        // get video vertical
                        Api.get_video_vertical_article(self.category, self.token,self.current_page, self.domain, function(rs){ 
                            self.current_page++;
                            self.articles_video_vertical = rs.data.data;
                            //update cache
                            localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-video-vertical", JSON.stringify(self.articles_video_vertical) );
                        });
                        // get video highlight piala dunia
                        Api.get_tag_article('457312', self.token,self.current_page, self.domain, function(rs){ 
                            self.current_page++;
                            self.articles_video_highlight = rs.data.data;
                            //update cache
                            localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-video-highlight", JSON.stringify(self.articles_video_highlight) );
                        });
                    }
                    Api.get_category_article(self.category, self.token,self.current_page, self.domain, function(rs){ // get articles from api
                        self.current_page++;
                        self.articles_all = rs.data.data;
                        let articles_slice = self.articles_all.slice(self.articles_show, self.limit);
                        articles_slice.forEach(function(article){
                            self.articles.push(article);
                            self.articles_show++;
                        });
                        
                        let photo = self.get_image(self.articles_all[0].photos);

                        self.update_meta({
                            image: photo,
                            url: config.get('base_url')+"-category-"+self.category
                        });
                        
                        self.loadingRouteData = false;

                        //update cache
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.last_update_category`) + "-" + self.category, new Date().toString() );
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-" + self.category, JSON.stringify(self.articles_all) );

                        self.handleScroll();
                    });
                });
            }
        }
        // higlight piala dunia
        window.dataLayer = window.dataLayer || [];
        /*window.dataLayer.push({
            event: "impression",
            feature_name: "gateway-piala-dunia",
            feature_location: "top",
            feature_position: "gateway-piala-dunia",
            kanal_page: "homepage"
        });
        window.dataLayer.push({
            event: "impression",
            feature_name: "widget-jadwal-piala-dunia" ,
            feature_location: "top",
            feature_position: "widget-jadwal-piala-dunia",
            kanal_page : "homepage"
        });*/
        
    },
    methods: {
        link_detail: function(article_title, article_id, article_uid, source, track, silkroad)
        {
            var slug = article_title.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
            var articleId = silkroad ? 'v'+article_id : article_id;
            var articleUid = silkroad ? 'v'+article_uid : article_uid;
            if(this.domain == 'liputan6id')
            {
                return this.$router.push({path:'/read/'+articleId+'/'+slug, params:{ id: article_id, slug:slug }});
            }
            else if(this.domain == 'todaykapanlagi' || this.domain == 'todaymerdeka')
            {
                return window.location.href= config.get('base_url')+"/"+ articleId;
            }
            else if(this.domain == 'enamplusliputan6')
            {
                if(track){
                    if(track.includes("video-spotlights")){
                        window.dataLayer = window.dataLayer || [];
                        var feature_position= track.split("-")
                        feature_position= parseInt(feature_position[feature_position.length - 1]) + 1;
                        window.dataLayer.push({
                            event: "click",
                            feature_name: "video-spotlights",
                            feature_location: "top",
                            feature_position: feature_position
                        });
                    }
                    // higlight piala dunia
                    /*if(track.includes("video-highlights")){
                        window.dataLayer = window.dataLayer || [];
                        var feature_position= track.split("-")
                        feature_position= parseInt(feature_position[feature_position.length - 1]) + 1;
                        window.dataLayer.push({
                            event: "click",
                            feature_name: "gateway-piala-dunia",
                            feature_location: "top",
                            feature_position: feature_position,
                            Kanal_page : "homepage"
                        });
                    }*/
                    if(track.includes("video-terkait")){
                        window.dataLayer = window.dataLayer || [];
                        var feature_position= track.split("-")
                        feature_position= parseInt(feature_position[feature_position.length - 1]) + 1;
                        window.dataLayer.push({
                            event: "click",
                            feature_name: "video-listing",
                            feature_location: "homepage",
                            feature_position: feature_position,
                            kanal_page: "homepage"
                        });
                    }
                }
                if (source.includes('bola.com')) {
                    source = source.split('.');
                    source[1] = 'liputan6';
                    source = source.join('.');
                }
                
                var path = source.replace(/^[^.]*/, 'https://enamplus');

                if(config.get("environtment") == 'devel')
                {
                    var path = source.replace(/^[^.]*/, 'https://staging-enamplus');
                }
                
                return window.location.href= path;
            }
            else
            {
                return window.location.href= config.get('base_url')+"/"+ articleUid.replace('liputan6.com-', '').replace('silkroad-', '');
            }
            
        },
        load_more: function()
        {
            let self = this;
            let offset = self.limit + self.articles_show;
            self.current_page = Math.ceil( self.articles_all.length / 20 ) + 1;

            if(self.articles_all.length < offset)
            {
                if(self.process_load_api == false)
                {
                    self.process_load_api = true;
                
                    Api.get_category_article(self.category, self.token,self.current_page, self.domain, function(rs){
                        let unique_article = self.unique_article(rs.data.data);

                        self.articles_all = self.articles_all.concat(unique_article);
                        //update cache
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.last_update_category`) + "-" + self.category, new Date().toString() );
                        localStorage.setItem(config.get(`stored.${self.$store.state.auth.login}.list_category_articles`) + "-" + self.category, JSON.stringify(self.articles_all) );

                        let articles_slice = self.articles_all.slice(self.articles_show, offset);
                        
                        articles_slice.forEach(function(article){
                            self.articles.push(article);
                            self.articles_show++;
                        });
                        self.current_page++;
                        self.process_load_api = false;


                        let page = self.articles_show / 6;

                        self.$gtm.trackEvent({
                            event: 'pageview', // Event type [default = 'interaction'] (Optional)
                            hitType: 'pageview',
                            page: self.$route.path +'?page='+ page,
                            title: document.title,
                            location: config.get('base_url')+"-category-"+self.category,
                            noninteraction: false // Optional
                        },{
                            'dimension1':'',
                            'dimension2': 'root',
                            'dimension3':'',
                            'dimension4':''
                        });
                        // ga('send',{
                        //     hitType: 'pageview',
                        //     page: self.$route.path +'?page='+ page,
                        //     title: document.title,
                        //     location: config.get('base_url')
                        // },{
                        //     'dimension1':'',
                        //     'dimension2': 'root',
                        //     'dimension3':'',
                        //     'dimension4':''
                        // });
                        if (rs.data.data.length==0) {
                            self.stop_load = true;
                        }
                    });
                }
            }
            else
            {
                let articles_slice = self.articles_all.slice(self.articles_show, offset);
                articles_slice.forEach(function(article){
                    self.articles.push(article);
                    self.articles_show++;
                });

                let page = self.articles_show / 6;

                self.$gtm.trackEvent({
                    event: 'pageview', // Event type [default = 'interaction'] (Optional)
                    hitType: 'pageview',
                    page: self.$route.path +'?page='+ page,
                    title: document.title,
                    location: config.get('base_url')+"-category-"+self.category,
                    noninteraction: false // Optional
                },{
                    'dimension1':'',
                    'dimension2': 'root',
                    'dimension3':'',
                    'dimension4':''
                });
                // ga('send',{
                //     hitType: 'pageview',
                //     page: self.$route.path +'?page='+ page,
                //     title: document.title,
                //     location: config.get('base_url')
                // },{
                //     'dimension1':'',
                //     'dimension2': 'root',
                //     'dimension3':'',
                //     'dimension4':''
                // });
            }
        },
        handleScrollSpotlights (count) 
        {
            let self = this;
            if (self.spotlights_scroll) {
                let elem = document.getElementsByClassName("spotlights_list")[0]
                window.dataLayer = window.dataLayer || [];
                if(elem.scrollLeft + elem.clientWidth >= elem.scrollWidth) {
                    // trigger right
                    var feature_position= count
                    window.dataLayer.push({
                        event: "impression",
                        feature_name: "video-spotlights",
                        feature_location: "top",
                        feature_position: "last-item"
                    });
                    self.spotlights_scroll=false
                }
                else if(elem.scrollLeft === 0) {
                    // trigger left
                    // var feature_position= 1
                    // window.dataLayer.push({
                    //     event: "impression",
                    //     feature_name: "video-spotlights",
                    //     feature_location: "top",
                    //     feature_position: feature_position
                    // });
                    // self.spotlights_scroll=false
                }
            }
        },
        handleScrollHighlights (count) 
        {
            let self = this;
            if (self.highlights_scroll) {
                let elem = document.getElementsByClassName("highlights_list")[0]
                window.dataLayer = window.dataLayer || [];
                if(elem.scrollLeft + elem.clientWidth >= elem.scrollWidth) {
                    // trigger right
                    /*window.dataLayer.push({
                        event: "impression",
                        feature_name: "gateway-piala-dunia",
                        feature_location: "top",
                        feature_position: "last-item",
                        kanal_page: "homepage"
                    });*/
                    self.highlights_scroll=false
                }
                else if(elem.scrollLeft === 0) {
                    // trigger left
                    // var feature_position= 1
                    // window.dataLayer.push({
                    //     event: "impression",
                    //     feature_name: "video-spotlights",
                    //     feature_location: "top",
                    //     feature_position: feature_position
                    // });
                    // self.spotlights_scroll=false
                }
            }
        },
        handleScroll (event) 
        {
            var self = this;
            let el = document.getElementById("footer");
            let bottom = this.ismobile() ? 450 : 550;
            var base_url = config.get("base_url");
           
            if (base_url.indexOf('enamplus.') > 0) {
                window.dataLayer = window.dataLayer || [];
                if (isScrolledIntoView(document.getElementsByClassName("image_article")[self.params.enamplus_video_passed])) {
                    window.dataLayer.push({
                        event: "impression",
                        feature_name: "video-listing",
                        feature_location: "homepage",
                        feature_position: self.params.enamplus_video_passed+1,
                        kanal_page: "homepage"
                    });
                    self.params.enamplus_video_passed++
                }
                if(isScrolledIntoView(document.getElementsByClassName("spotlights_list")[0])&& !self.params.enamplus_video_spotlight_passed) {
                    // trigger left
                    window.dataLayer.push({
                        event: "impression",
                        feature_name: "video-spotlights",
                        feature_location: "top",
                        feature_position: "first-item"
                    });
                    self.params.enamplus_video_spotlight_passed=true
                }
                function isScrolledIntoView(elem) {
                    if (elem!==undefined) {
                        var rect = elem.getBoundingClientRect();
                        var elemTop = rect.top;
                        var elemBottom = rect.bottom;
                        // Partially visible elements return true:
                        var isVisible = elemTop < (window.innerHeight-50) && elemBottom >= 0;
                        return isVisible;
                    }
                    return false;
                }
            }
            if ( ((window.innerHeight + window.scrollY) >= (el.offsetTop - bottom)) ) 
            {
                this.load_more();
            }
        },
        unique_article: function(list_article)
        {
            let self = this;
            let ids = self.articles_all.map(function (v) { return v.id; });

            let found = list_article.filter(function(v) {
                var exists = ids.indexOf(v.id);
                return exists < 0;
            });
            
            return found;
        },
        format_duration: function(duration)
        {
            var hour = Math.floor(duration / 3600)
            var minute = ("0" + Math.floor(duration % 3600 / 60)).slice(-2)
            var second = ("0" + Math.floor(duration % 3600 % 60)).slice(-2)

            return hour>0 ? hour+":"+minute+":"+second : minute+":"+second
        },
        capitalizeFirstLetter: function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        link_more: function(source)
        {
            if(this.domain.includes('enamplus'))
            {
                window.dataLayer = window.dataLayer || [];
                /*window.dataLayer.push({
                    event: "click",
                    feature_name: "gateway-piala-dunia",
                    feature_location: "top",
                    feature_position: "selengkapnya",
                    Kanal_page: "homepage"
                });*/
                var path = source;
                this.$router.push(path)
            }
        },
    },
    watch: {
        stop_load: function () {
        window.removeEventListener('scroll', this.handleScroll);
        } 
    }
}
</script>