<template>
    <div>
        <div class="header">
            <div v-if="domain" :class="['container', { 'enamplus': domain.includes('enamplus') }]">
                <div class="header_logo">
                    <!--
                    <a @click="$router.go(-1)" v-if="$route.params.slug !== undefined" class="arrow-back" aria-label="Back"></a>
                    -->
                    <router-link to="/" :class="'logo logo-header header-'+headerDomain" aria-label="Home"></router-link>
                    <div class="header_right" v-if="domain == 'liputan6id'">
                        <a @click="event_click()" class="btn btn--live">LIVE</a>
                        <a @click="login_click()" class="login">
                            <!-- <i class="icon icon-user"></i> -->
                            <img class="icon icon-user" :src="login_icon()" @error="img_user_on_error">
                        </a>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="main">
            <!-- /36504930/m.liputan6.com/dfp-headline -->
            <div id='div-gpt-ad-liputan6-hl' data-info='ad'></div>
            <div  v-if="domain">
                <div class="container enamplus" v-if="domain.includes('enamplus')">
                    <div class="top-nav">
                        <ul class="top-nav__list js-top-nav-list" :style="{'margin-bottom':($route.name == 'Tag'?'20px':'0')}">
                            <li :class="'top-nav--item top-nav--item top-nav--item-home'+ ($route.name == 'Home' ? ' top-nav--item_active' : '')"><a @click="link_menu('/', 'home')" class="top-nav--item__link"
                                ><i class="menubar-home-icon"></i><span>Home</span></a></li>
                            <!-- <li :class="'top-nav--item ' + ($route.params.category == 'Video' ? ' top-nav--item_active' : '')" data-title="TopStories"  
                                data-component="mobile:top-nav:item" data-component-name="mobile:top-nav:item"><div @click="link_menu('/Video')"
                                    class="top-nav--item__link" ><span>Video</span></div></li> -->
                            
                            <!-- piala dunia
                            <li :class="'top-nav--item ' + ($route.params.category == 'piala-dunia' ? ' top-nav--item_active' : '')" data-title="Video::PialaDunia" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/tag/457312/highlights-piala-dunia', 'piala dunia')" class="top-nav--item__link"
                                    ><span class="piala-dunia"></span></a></li>
                            -->
                            
                            <li :class="'top-nav--item ' + ($route.params.category == 'news' ? ' top-nav--item_active' : '')" data-title="Video::News" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/news', 'news')" class="top-nav--item__link"
                                    ><span>News</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'showbiz' ? ' top-nav--item_active' : '')" data-title="Video::Showbiz" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/showbiz', 'showbiz')" class="top-nav--item__link"
                                    ><span>Showbiz</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'bola' ? ' top-nav--item_active' : '')" data-title="Video::Bola" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/bola', 'bola')" class="top-nav--item__link"
                                    ><span>Bola</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'health' ? ' top-nav--item_active' : '')" data-title="Video::Health" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/health', 'health')" class="top-nav--item__link"
                                    ><span>Health</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'bisnis' ? ' top-nav--item_active' : '')" data-title="Video::Bisnis" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/bisnis', 'bisnis')" class="top-nav--item__link"
                                    ><span>Bisnis</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'citizen6' ? ' top-nav--item_active' : '')" data-title="Video::Citizen6" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/citizen6', 'citizen6')" class="top-nav--item__link"
                                    ><span>Citizen6</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'musik' ? ' top-nav--item_active' : '')" data-title="Video::Musik" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/musik', 'musik')" class="top-nav--item__link"
                                    ><span>Musik</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'global' ? ' top-nav--item_active' : '')" data-title="Video::Global" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/global', 'global')" class="top-nav--item__link"
                                    ><span>Global</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'tekno' ? ' top-nav--item_active' : '')" data-title="Video::Tekno" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/tekno', 'tekno')" class="top-nav--item__link"
                                    ><span>Tekno</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'lifestyle' ? ' top-nav--item_active' : '')" data-title="Video::Lifestyle" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/lifestyle', 'lifestyle')" class="top-nav--item__link"
                                    ><span>Lifestyle</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'otomotif' ? ' top-nav--item_active' : '')" data-title="Video::Otomotif" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/otomotif', 'otomotif')" class="top-nav--item__link"
                                    ><span>Otomotif</span></a></li>
                            <li :class="'top-nav--item ' + ($route.params.category == 'regional' ? ' top-nav--item_active' : '')" data-title="Video::Regional" data-component="mobile:top-nav:item"
                                data-component-name="mobile:top-nav:item"><a @click="link_menu('/regional', 'regional')" class="top-nav--item__link"
                                    ><span>Regional</span></a></li>
                        </ul>
                    </div>
                    <router-view key="/" name="enamplus" v-if="$route.name == 'Home'"></router-view>
                    <router-view :key="$route.fullPath" v-else></router-view>
                </div>
                <div class="container" v-else>
                    <div class="main_body">
                        <div class="tab" v-if="($route.name == 'Home' || $route.name == 'Popular') && tabPopular">
                            <ul class="tab__nav">
                                <li class="slider"></li>
                                <li v-bind:class="{ active: $route.name == 'Home' }"><a href="javascript:void(0)" id="tab_terkini" v-on:click="change_tab">Terkini</a></li>
                                <li v-bind:class="{ active: $route.name == 'Popular' }"><a href="javascript:void(0)" id="tab_terpopuler" v-on:click="change_tab">Terpopuler</a></li>
                            </ul>
                        </div>
                        <transition name="slide-fade" v-if="after_load_login">
                            <router-view :key="$route.fullPath"></router-view>
                        </transition>
                        <loading v-else></loading>
                    </div>
                </div>
            </div>
            <!-- /36504930/m.liputan6.com/channel/dfp-sc -->
            <div id='div-gpt-ad-liputan6-sc'></div>
            <!-- <img src="assets/pwa/assets/illustration.png" :alt="Ilustrator" class="img-ilustrator"> -->
        </div>
        <div  v-if="domain">
            <div id="footer" v-if="(($route.name == 'Category' && domain.includes('enamplus')) || ($route.name == 'Home' && domain.includes('enamplus')))">
            </div>
            <div class="footer" id="footer" v-else>
                <div class="container">
                    <!--
                    <a @click="back_to_top()" class="backtop fixed" style="bottom: 81px;" v-if="show_back_to_top"></a>
                    -->
                    <a @click="back_page()" v-if="$route.params.slug !== undefined && show_back_to_top" class="backpage fixed" aria-label="Back"></a>
                    <p class="footer-copyright">Copyright © {{year}} <router-link to="/" :aria-label="copyrightDomain">{{copyrightDomain}}</router-link> <br>KLY KapanLagi Youniverse All Rights Reserved</p>
                </div>
            </div>
        </div>
        <!-- <div class="unlogin" id="unlogin" v-show="$store.state.auth.login == 0 && $route.params.slug != undefined && isMobile === false">
            <h4>Belum selesai baca berita ini?</h4>
            <p>Silakan masuk/daftar untuk melanjutkan membaca Konten tanpa ganguan Ads</p>
            <a @click="login_click()" class="unlogin_btn">Masuk / Daftar</a>
        </div> -->
        <div class="unlogin" id="unlogin" v-show="$store.state.auth.login == 0 && $route.params.slug != undefined">
            <h4>Belum selesai baca berita ini?</h4>
            <p>Baca berita tanpa gangguan Ads dan dapatkan berita-berita terupdate!</p>
            <a href="https://appliputan6.page.link/store" class="unlogin_btn">Baca di Aplikasi</a>
        </div>
    </div>
</template>

<script> 
import config from '@/config/config'
import { global_function } from '@/global_function.js'
import Api from '@/services/Api'

export default {
    mixins: [global_function],
    data () {
        return {
            detail: false,
            show_back_to_top: false,
            slug_before: null,
            after_load_login: false,
            auth: {
                login: 0
            },
            year: 2019,
            isMobile: false,
            domain: "liputan6id",
            headerDomain: "liputan6id",
            tabPopular: false,
            copyrightDomain: "liputan6.id",
        }
    },
    mounted () {
        var self = this;
        var base_url = config.get("base_url");
        var domain = "liputan6id";
        if(base_url.indexOf('today.') > 0) {
            const array = base_url.split('.');
            domain = "today"+array[1];
        }
        if(base_url.indexOf('trend.') > 0) {
            const array = base_url.split('.');
            domain = "trend"+array[1];
        }
        if(base_url.indexOf('enamplus.') > 0) {
            const array = base_url.split('.');
            domain = "enamplus"+array[1];
            this.copyrightDomain="enamplus.liputan6.com"
        }
        if(base_url.indexOf('youniverse.') > 0) {
            domain = "youniverse";
        }
        self.domain = domain;
        
        if(domain == "liputan6id"){
            Api.status_login(function(rs){
                self.$store.state.auth = rs.data;
                self.after_load_login = true; // comment because if status login error next code error
            }, function() {
                self.after_load_login = true;
            });
        }else{
            self.after_load_login = true;
        }
        
        if (this.domain.includes('today')||this.domain.includes('trend')) {
            this.headerDomain=self.domain.replaceAll('trend','today')
            if (this.domain.includes('merdeka')||this.domain.includes('otosia')) {
                this.tabPopular=false
            }else{
                this.tabPopular=true
            }
        }else if(this.domain.includes('enamplus')){
            this.headerDomain=self.domain
            this.tabPopular=false
        }else{
            this.tabPopular=true
        }
        this.year = new Date().getFullYear();

        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
            this.isMobile = true;
        }
    },
    methods: {
        link_menu: function(source, menu)
        {
            if(this.domain.includes('enamplus'))
            {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "click",
                    feature_name: "top-nav",
                    feature_location: "top",
                    feature_position: menu,
                    kanal_page:'homepage'
                });
                
                var path = source;

                this.$router.push(path)
            }
        },
        goBack: function()
        {
            $router.go(-1);
        },
        handleScroll (event) 
        {
            var self = this;
            self.load_pages = true;
            let el = document.getElementById("footer");

            if ( window.scrollY > 300 ) 
            {
                self.show_back_to_top = true;
            }
            else
            {
                self.show_back_to_top = false;
            }

            if(self.$route.params.slug && window.scrollY > 300 && window.scrollY < 400)
            {
                if(self.$route.params.slug !== self.slug_before){
                    self.slug_before = self.$route.params.slug;
                    let article_id = self.$route.params.id;
                    let article_stored = localStorage.getItem(config.get(`stored.${this.$store.state.auth.login}.list_detail_articles`) + "-" + article_id );
                    if(article_stored !== undefined && article_stored !== null)
                    {
                        article_stored = JSON.parse(article_stored);
                        let sub_category = article_stored.category.category_name;
                        sub_category = sub_category !== undefined ? sub_category.toLowerCase() : '';

                        let publisher_name = article_stored.publisher.publisher_name;
                        publisher_name = publisher_name !== undefined ? publisher_name.toLowerCase() : '';
                        
                        // ga('send', 'event', 'btn-back', 'impression', 'btn-back::'+sub_category+'::'+publisher_name);
                    }
                }
            }
        },
        back_page()
        {
            var self = this;
            let article_id = self.$route.params.id;
            let article_stored = localStorage.getItem(config.get(`stored.${this.$store.state.auth.login}.list_detail_articles`) + "-" + article_id );

            if(article_stored !== undefined && article_stored !== null)
            {
                article_stored = JSON.parse(article_stored);

                let sub_category = article_stored.category.category_name;
                sub_category = sub_category !== undefined ? sub_category.toLowerCase() : '';

                let publisher_name = article_stored.publisher.publisher_name;
                publisher_name = publisher_name !== undefined ? publisher_name.toLowerCase() : '';

                self.$gtm.trackEvent({
                    event: 'event', // Event type [default = 'interaction'] (Optional)
                    category: 'btn-back',
                    action: 'click',
                    label: 'btn-back::'+sub_category+'::'+publisher_name,
                    noninteraction: false // Optional
                });

                // ga('send', 'event', 'btn-back', 'click', 'btn-back::'+sub_category+'::'+publisher_name);
            }

            self.$router.go(-1);
        },
        login_icon() 
        {
            return this.$store.state.auth.login  == 1 ? this.$store.state.auth.user.profile_photo_url : config.get('base_url')+'/assets/images/login_avatar.png';
        },
        event_click() 
        {
            return window.location.href = config.get('base_url') + '/event'; 
        },
        login_click() 
        {
            if (this.$store.state.auth.login != 0) {
                return window.location.href = config.get('base_url') + '/dashboard';
            } else {
                return window.location.href = config.get('base_url') + '/login?referrer=' + encodeURIComponent(config.get('base_url') + this.$route.path);
            }
        },
        img_user_on_error: function(e)
        {
            e.target.src = config.get('base_url')+'/assets/images/login_avatar.png';
        },
        change_tab: function(e) {
            var el = e.target;
            var parent = el.parentElement;
            var position = parent.offsetLeft;
            var width = parent.offsetWidth;

            var tabNav = document.getElementsByClassName("tab__nav");
            var tabSlider = tabNav[0].querySelector('.slider');
            tabSlider.style.left = position + "px";
            tabSlider.style.width = width + "px";

            var redirect = '/';
            if (el.id == 'tab_terkini') {
                document.getElementById('tab_terpopuler').parentElement.classList.remove("active");
            } else {
                document.getElementById('tab_terkini').parentElement.classList.remove("active");
                redirect= '/popular';
            }
            parent.classList.add("active");

            return this.$router.push({path:redirect, params:{}});
        }
    },
    created: function() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () 
    {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>